
// TODO: Get all the URLs/alias from Strapi and use it here
export const URLS = {
  VACANCIES: {
    name: 'Vacancy',
    alias: '/vacancy-type',
  },
  LATEST_NEWS: {
    name: 'News',
    alias: '/news',
  },
}


export const VACANCIES_PAGE_URL = URLS.VACANCIES;
export const LATEST_NEWS_PAGE_URL = URLS.LATEST_NEWS;
